<template>
  <div v-if="showNews" class="modal">
    <div class="modal-wrapper">
      <div class="modal-header">
        <div class="title">New bRing BSC pools</div>
        <button @click="$emit('closeNews')" data-cv="btn-closeNewsWindow"> <i class="i-close"></i></button>
      </div>
      <div class="modal-content  modal-content_stake ">

        <!-- ------- -->
        <div >
          <div class="modal-block_tokens">
            <div class="token">
              <div class="token_img"><div class="token-brng-lp"></div></div>
              <div class="token_title">BRNG-LP</div>
            </div>
            <div class="token">
              <div class="token_img"><div class="token-weld-lp"></div></div>
              <div class="token_title">WELD-LP</div>
            </div>
            <div class="token">
              <div class="token_img"><div class="token-smaugs-lp"></div></div>
              <div class="token_title">SMG-LP</div>
            </div>
            <div class="token">
              <div class="token_img"><div class="token-yarloo-lp"></div></div>
              <div class="token_title">YARL-LP</div>
            </div>
          </div>
          <!-- <ul class="modal-advantages">
            <li> 
              <div></div> 
              <span>The new BSC pool has already launched!</span>
            </li>
            <li> 
              <div></div> 
              <span>Our 2nd BSC Ring is open for staking! 
                Stake LP-tokens of participating projects in the pool - 
                Smaugs, Weld, Yarloo, bRing. And get all 4 tokens 
                as a reward.</span> 
            </li>
            <li> 
              <div></div> 
              <span>Don't wait. Just stake!</span> 
            </li>
          </ul> -->
          <div class="modal-advantages">
            The new BSC pool has already launched!
            <br/><br/>
            Our 2nd BSC Ring is open for staking! Stake LP-tokens of participating projects in the pool - Smaugs, Weld, Yarloo, bRing. And get all 4 tokens as a reward.
            <br/><br/>
            Don't wait. Just stake!
          </div>
          <a class="button button_modal-link" ref="nofollow" href="https://bsc.bring.finance">Start staking</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
export default {
  name: "NewsModal",
  props: ['showNews'],
  data: function() {
    return {
      lang: new MultiLang(this),
    }
  },
  mounted() {
  this.lang.init()
}
}
</script>