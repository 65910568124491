<template>
    <div class="wrapper wrapper_home" id="home">
        <div class="links-main">
            <div class="container-link" data-cy="list-links">
                <div>
                    <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                </div>
                <div>
                    <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                </div>
                <div>
                    <a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg" target="_blank" rel="nofollow"><span class="youtube"></span></a>
                </div>
                <div>
                    <a href="https://www.reddit.com/r/bRingFinance/" target="_blank"><span class="reddit" rel="nofollow"></span></a>
                </div>
                <div>
                    <a href="https://t.me/bRing_community" target="_blank"><span class="telegram" rel="nofollow"></span></a>
                </div>
                <div>
                    <a href="https://t.me/bRing_channel" target="_blank"
                        ><span class="telegram speaker" rel="nofollow"> <i class="i-speaker"></i></span
                    ></a>
                </div>
            </div>
        </div>

        <header-component @showInstallWalletModal="$emit('showInstallWalletModal')" />
        <div>
            <main class="landing">
                <news-modal :showNews="showNews" @closeNews="showNews = !showNews" />
                <section class="main_section">
                    <div class="container">
                        <div class="" id="our_partners">
                            <h1>{{ lang.get("MAIN_TITLE") }}</h1>
                            <!-- <div  class="h3 "></div> -->
                            <!-- <button class="button button_launch-app" data-cy="button_launch-app-main">Launch app</button> -->
                        </div>
                        <div class="container-partners">
                            <div class="h3">{{ lang.get("PARTNERS") }}</div>
                            <div class="partners" data-cy="list-partners">
                                <a
                                    href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf7413489c474ca4399eee604716c72879eea3615&use=V2"
                                    class="apyswap"
                                    target="_blank"
                                    rel="nofollow"
                                ></a>
                                <a href="https://yellowroad.app/" class="yeallowroad" target="_blank" rel="nofollow"></a>
                                <a href="https://a2dao.com/" class="a2dao" target="_blank" rel="nofollow"></a>
                                <a href="https://kyros.ventures/" class="kyros" target="_blank" rel="nofollow"></a>
                                <a
                                    href="https://app.uniswap.org/#/swap?outputCurrency=0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54&use=V2"
                                    class="hapi"
                                    target="_blank"
                                    rel="nofollow"
                                ></a>
                                <a href="https://1mlnnfts.com/" class="mil_ntf" target="_blank" rel="nofollow"></a>
                                <a href="https://peanut.trade/" class="peanut" target="_blank" rel="nofollow"></a>
                                <a href="https://www.raze.network/" class="raze" target="_blank" rel="nofollow"></a>
                                <a href="https://ferrum.network/" class="ferrum" target="_blank" rel="nofollow"></a>
                                <a href="https://memeunity.com/" class="memeunity" target="_blank" rel="nofollow"></a>
                                <a href="https://dehive.finance/" class="dehive" target="_blank" rel="nofollow"></a>
                                <a href="https://coinmarketcap.com/currencies/bring-finance/" class="coinmarket" target="_blank" rel="nofollow"></a>
                                <a href="https://bulknetwork.xyz/" class="bulknetwork" target="_blank" rel="nofollow"></a>
                                <a href="https://www.hedget.com/" class="hadge" target="_blank" rel="nofollow"></a>
                                <a href="https://berrydata.co/#/home" class="berrydata" target="_blank" rel="nofollow"></a>
                                <a href="https://ibetyou.xyz/" class="ibetyou" target="_blank" rel="nofollow"></a>
                                <a href="https://www.gamyfi.org/home" class="gamyfi" target="_blank" rel="nofollow"></a>
                                <a href="https://www.fintropy.io/" class="fintropy" target="_blank" rel="nofollow"></a>
                                <a href="https://polkalokr.com/" class="polkalokr" target="_blank" rel="nofollow"></a>
                                <a href="https://skyrim.finance/" class="skyrim" target="_blank" rel="nofollow"></a>
                                <a href="https://yarloo.io/" class="yarloo" target="_blank" rel="nofollow"></a>
                                <a href="https://teneo.finance/" class="teneo" target="_blank" rel="nofollow"></a>
                                <a href="https://www.smaugs.com/" class="smaugs" target="_blank" rel="nofollow"></a>
                                <a href="https://www.gamestation.io/" class="gamestation" target="_blank" rel="nofollow"></a>
                                <a href="https://app.usemate.com/" class="usemate" target="_blank" rel="nofollow"></a>
                                <a href="https://weld.money/" class="weld" target="_blank" rel="nofollow"></a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="our_service" id="bRing_features">
                    <div class="container">
                        <div class="two_parts">
                            <div>
                                <span class="h2_home">{{ lang.get("MAIN_FEATURES_TITLE") }}</span>
                                <div class="h3">{{ lang.get("MAIN_FEATURES_SUBTITLE") }}</div>
                                <ul class="advantages">
                                    <li>
                                        <div><i class="i-coins"></i></div>
                                        <span>{{ lang.get("FEATURE_1") }}</span>
                                    </li>
                                    <li>
                                        <div><i class="i-grow"></i></div>
                                        <span>{{ lang.get("FEATURE_2") }}</span>
                                    </li>
                                    <li>
                                        <div><i class="i-wallet"></i></div>
                                        <span>{{ lang.get("FEATURE_3") }}</span>
                                    </li>
                                    <li>
                                        <div><i class="i-unlock"></i></div>
                                        <span>{{ lang.get("FEATURE_4") }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="block-image">
                                <div class="image">
                                    <div class="image-wrapper">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <ul class="our_statistics">
              <li>
                <span  class="h2_home">--</span>
                <div  class="h4">{{lang.get("STATS_1")}}</div>
              </li>
              <li>
                <span  class="h2_home">--</span>
                <div  class="h4">{{lang.get("STATS_2")}}</div>
              </li>
              <li>
                <span  class="h2_home">--</span>
                <div  class="h4">{{lang.get("STATS_3")}}</div>
              </li>
              <li>
                <span  class="h2_home">--</span>
                <div  class="h4">{{lang.get("STATS_4")}}</div>
              </li>
            </ul> -->
                    </div>
                </section>
                <section class="how_it_works" id="bRing_pools">
                    <div class="container">
                        <span class="h2_home">bRing {{ lang.get("POOLS") }}</span>
                        <!-- <div  class="h3 "> </div> -->
                        <ul class="steps_works">
                            <li>
                                <div class="step"><span class="count"> 1 </span></div>
                                <div class="h3"></div>
                                <div class="h4">{{ lang.get("STEP_GUIDE_1") }}</div>
                            </li>
                            <li>
                                <div class="step"><span class="count"> 2 </span></div>
                                <div class="h3"></div>
                                <div class="h4">{{ lang.get("STEP_GUIDE_2") }}</div>
                            </li>
                            <li>
                                <div class="step"><span class="count"> 3 </span></div>
                                <div class="h3"></div>
                                <div class="h4">{{ lang.get("STEP_GUIDE_3") }}</div>
                            </li>
                            <li>
                                <div class="step"><span class="count"> 4 </span></div>
                                <div class="h3"></div>
                                <div class="h4">{{ lang.get("STEP_GUIDE_4") }}</div>
                            </li>
                        </ul>
                        <button v-if="currentAddress" @click="$router.push({ name: 'pool' })" class="button button_launch-app" data-cv="btn-launch-app">
                            {{ lang.get("LAUNCH_APP") }}
                        </button>
                    </div>
                </section>
                <section class="roadmap" id="roadmap">
                    <div class="container">
                        <span class="h2_home">{{ lang.get("ROADMAP") }}</span>
                        <!-- <div  class="h3 "></div> -->
                    </div>
                    <ul class="roadmap-list">
                        <li class="active">
                            <div>
                                <div class="title">Q1 2022</div>
                                <div class="block">
                                    <div></div>
                                </div>
                                <div v-html="lang.get('ROADMAP_DESC_1')" class="roadmap_description"></div>
                            </div>
                        </li>
                        <li class="active">
                            <div>
                                <div class="title">Q2 2022</div>
                                <div class="block">
                                    <div></div>
                                </div>
                                <div v-html="lang.get('ROADMAP_DESC_2')" class="roadmap_description"></div>
                            </div>
                        </li>
                        <li class="active">
                            <div>
                                <div class="title">Q3 2022</div>
                                <div class="block">
                                    <div></div>
                                </div>
                                <div v-html="lang.get('ROADMAP_DESC_3')" class="roadmap_description"></div>
                            </div>
                        </li>
                        <li class="active">
                            <div>
                                <div class="title">Q4 2022</div>
                                <div class="block">
                                    <div></div>
                                </div>
                                <div v-html="lang.get('ROADMAP_DESC_4')" class="roadmap_description"></div>
                            </div>
                        </li>
                    </ul>
                </section>
                <section class="solution" id="solution">
                    <div class="container">
                        <div class="solution_icon"></div>
                        <h2 class="solution_title">{{ lang.get("SOLUTION_TITLE") }}</h2>
                        <div class="solution_blocks">
                            <div v-html="lang.get('SOLUTION_BLOCK_1')"></div>
                            <!-- <div v-html='lang.get("SOLUTION_BLOCK_2")'></div> -->
                            <div v-html="lang.get('SOLUTION_BLOCK_3')"></div>
                        </div>
                    </div>
                </section>
                <section class="questions" id="questions">
                    <span class="h2_home">{{ lang.get("FAQs") }}</span>
                    <faq-blocks :faqs="faqs" @openFaq="openFaq($event)" />
                </section>
            </main>
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    import FaqBlocks from "../components/FaqBlocks.vue";
    import { mapState } from "vuex";
    import HeaderComponent from "../components/HeaderComponent.vue";
    import NewsModal from "../components/modalWindows/NewsModal.vue";

    export default {
        components: {
            FaqBlocks,
            HeaderComponent,
            NewsModal,
        },

        computed: {
            ...mapState(["currentAddress"]),
            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
        },
        data: function () {
            return {
                lang: new MultiLang(this),
                showNews: false,
                faqs: [
                    {
                        title: "FAQ_1_TITLE",
                        description: "FAQ_1_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_2_TITLE",
                        description: "FAQ_2_TEXT",
                        isOpen: false,
                    },
                    /*{
          title: "FAQ_3_TITLE",
          description:
            "FAQ_3_TEXT",
          isOpen: false,
        },*/
                    {
                        title: "FAQ_4_TITLE",
                        description: "FAQ_4_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_5_TITLE",
                        description: "FAQ_5_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_6_TITLE",
                        description: "FAQ_6_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_7_TITLE",
                        description: "FAQ_7_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_8_TITLE",
                        description: "FAQ_8_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_9_TITLE",
                        description: "FAQ_9_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_10_TITLE",
                        description: "FAQ_10_TEXT",
                        isOpen: false,
                    },
                ],
            };
        },
        mounted() {
            this.lang.init();

            // setTimeout(() => {
            //     const currentTime = Math.floor(new Date().getTime() / 1000);
            //     const seenNewsTimestamp = window.localStorage.getItem("seenNewsTimestamp");
            //     if (!seenNewsTimestamp || Number(seenNewsTimestamp) + 600 < currentTime) {
            //         this.showNews = true;
            //         window.localStorage.setItem("seenNewsTimestamp", currentTime);
            //     }
            // }, 1000);
        },
        methods: {
            //TODO change when deployed to binance
            connectWallet() {
                try {
                    let currentAccount = localStorage.getItem("address");
                    if (window.ethereum) {
                        window.ethereum
                            .request({ method: "eth_requestAccounts" })
                            .then(handleAccountsChanged)
                            .catch((err) => {
                                if (err.code == "-32002") {
                                    alert("Proceed to your metamask extension");
                                }
                            });

                        // Note that this event is emitted on page load.
                        // If the array of accounts is non-empty, you're already
                        // connected.
                        window.ethereum.on("accountsChanged", handleAccountsChanged);

                        // For now, 'eth_accounts' will continue to always return an array
                        function handleAccountsChanged(accounts) {
                            if (accounts.length === 0) {
                                localStorage.removeItem("address");
                                _this.$store.commit("setCurrentAddress", "");

                                // MetaMask is locked or the user has not connected any accounts
                                // alert('Please connect to MetaMask.');
                            } else if (accounts[0] !== currentAccount) {
                                currentAccount = accounts[0];
                                localStorage.setItem("address", currentAccount);
                                // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                                location.reload();
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            openFaq(index) {
                this.faqs.map((val, key) => {
                    if (val.isOpen && key === index) val.isOpen = false;
                    else val.isOpen = key === index;
                    return val;
                });
            },
        },
    };
</script>
