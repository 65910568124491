<template>
  <div id="app">
    <router-view  />
    <footer class="footer">
      <div class="container">
        <div>Copyright © 2021. All rights reserved.</div>
      </div>
    </footer>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import {mapState} from 'vuex';
// import ConnectWalletModal from '../src/components/modalWindows/ConnectWalletModal';
// import Core from "../src/core/core.js";
import ReferralController from "./controllers/ReferralController";


export default {
  data: function() {
    return {
      selectedWallet: "metamask",
      lang: new MultiLang(this),
    }
  },
   methods: {
    // checkWalletOption(wallet) {
    //   let _this = this;

    //   if(wallet === 'metamask'){
    //           window.ethereum
    //             .request({ method: 'eth_accounts' })
    //             .then(handleAccountsChanged)
    //             .catch((err) => {
    //               console.error(err);
    //             });

    //           window.ethereum.on('accountsChanged', handleAccountsChanged);

    //         function handleAccountsChanged(accounts) {
  
    //           if (accounts.length > 0) {

    //             _this.walletUnlocked = true;
    //           }
    //         }

    //   }else if(this.selectedWallet === 'binance') {
    //           window.BinanceChain
    //             .request({ method: 'eth_accounts' })
    //             .then(handleAccountsChanged)
    //             .catch((err) => {
    //               console.error(err);
    //             });
    //           window.BinanceChain.on('accountsChanged', handleAccountsChanged);

    //           function handleAccountsChanged(accounts) {
    //             if (accounts.length === 0) {
    //               _this.walletUnlocked = true;
    //             } 
    //             }
    //           }
    // },

    // setWalletOption(wallet) {
    //   let _this = this;
    //   this.selectedWallet = wallet;
    //   let currentAccount = localStorage.getItem("address");
    //   localStorage.setItem('selectedWallet', wallet);
    //   if(window.ethereum && this.selectedWallet === 'metamask'){
    //           window.ethereum
    //             .request({ method: 'eth_requestAccounts' })
    //             .then(handleAccountsChanged)
    //             .catch((err) => {
    //               console.error(err);
    //             });

    //           // Note that this event is emitted on page load.
    //           // If the array of accounts is non-empty, you're already
    //           // connected.
    //           window.ethereum.on('accountsChanged', handleAccountsChanged);

    //         // For now, 'eth_accounts' will continue to always return an array
    //         function handleAccountsChanged(accounts) {
    //           if (accounts.length === 0) {

    //             _this.walletUnlocked = false;
    //             localStorage.removeItem('address')
    //             _this.$store.commit("setCurrentAddress", "")

    //             // MetaMask is locked or the user has not connected any accounts
    //             // alert('Please connect to MetaMask.');
    //           } else if (accounts[0] !== currentAccount) {
    //             currentAccount = accounts[0];
    //             localStorage.setItem("address", currentAccount);
    //                   // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
    //             location.reload();
    //           }else if (accounts.length > 0) {
    //             _this.walletUnlocked = true;
    //           }
    //         }

    //   }else if(window.BinanceChain && this.selectedWallet === 'binance') {
    //           window.BinanceChain
    //             .request({ method: 'eth_accounts' })
    //             .then(handleAccountsChanged)
    //             .catch((err) => {
    //               // Some unexpected error.
    //               // For backwards compatibility reasons, if no accounts are available,
    //               // eth_accounts will return an empty array.
    //               console.error(err);
    //             });

    //           // Note that this event is emitted on page load.
    //           // If the array of accounts is non-empty, you're already
    //           // connected.
    //           window.BinanceChain.on('accountsChanged', handleAccountsChanged);

    //           // For now, 'eth_accounts' will continue to always return an array
    //           function handleAccountsChanged(accounts) {
    //             if (accounts.length === 0) {
    //               // Binance Chain Wallet is locked or the user has not connected any accounts
    //               alert('Please connect to Binance Chain Wallet.');
    //             } else if (accounts[0] !== currentAccount) {
    //               currentAccount = accounts[0];
    //               // Do any other work!

    //               localStorage.setItem("address", currentAccount);
    //               location.reload();
    //             }
    //           }
      
    //   // location.reload()
    // }else if(!window.ethereum || !window.BinanceChain){
    //   _this.noWallet=true;
    // }
    // },



    // getWalletOption() {
    //   const selWallet = localStorage.getItem('selectedWallet');
    //   if(!selWallet) {
    //     this.showWalletOptions = true;
    //     return null;
    //   }else {
    //     return selWallet;
    //   }
    // },

    

    // handleChainChanged(_chainId) {
    //   // We recommend reloading the page, unless you must do otherwise

    //   window.location.reload();
    // }
    

  },
  computed: {
    ...mapState(['currentAddress']),

    addressShort() {
      if(this.currentAddress){
        return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length)
      }
      }
  },
  async mounted () {
     this.lang.init();

    // window.addEventListener("message", async function (e) {
    //     if(e.data && e.data.data && e.data.data.data && e.data.data.data.method === "metamask_unlockStateChanged") {
    //       this.walletUnlocked = e.data.data.data.params.isUnlocked;
    //     }
    // })

    // // let connected = await window.ethereum.isConnected();
    // // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    // // if(connected && chainId !== "0x61") {

    // // }
    
    // let tronConnectAttemptsCounter = 0;
    // let _this = this;
    ReferralController.setReferrerAddressIfExists(this);
    // const walletOpiton = "metamask";
    // // const walletOpiton = _this.getWalletOption(); //TODO return when deployed to binance
    // setTimeout(async function initContract() {
    //   try {
    //         _this.$root.core = new Core(_this)

            
    //     if(walletOpiton) {
    //       if(walletOpiton === 'binance' && !window.BinanceChain) {
    //         throw Error('No Binance wallet found');

    //       }else if(walletOpiton === 'metamask' && !window.ethereum) {
    //         throw Error('No Metamask extension found');
    //       }

    //       if(window.ethereum || window.BinanceChain) {
    //         window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
    //         let currentAccount = localStorage.getItem('address');
    //         _this.$store.commit("setCurrentAddress", currentAccount);
    //         // _this.setWalletOption(_this.getWalletOption()) //TODO return when deployed to binance
    //         _this.setWalletOption('metamask')

          
    //         // _this.$root.core.updateSiteStats(50000);
    //         // _this.$root.core.getCurrentRate();
            
    //         // if(currentAccount){
    //         //   _this.$root.core.updateUserStatsAndTokensBalance(5000, currentAccount);
              
    //         // }

    //         let result = await _this.$root.core.init();
    //         if(result == undefined){
    //           throw Error();
    //         }
    //         _this.$root.core.getSiteData();
    //         _this.$root.core.getUserReward(currentAccount)
    //         _this.$root.core.updateUserInfo(currentAccount)
    //         _this.$root.core.getOptimalGasPrice();


            

    //       }else{
    //         _this.noWallet = true;
    //         _this.$root.core = new Core(_this)
    //         // _this.$root.core.updateSiteStats(50000);
    //         // _this.$root.core.getCurrentRate();
    //       }
    //     }else {
    //       // _this.showWalletOptions = true;
    //       _this.$root.core = new Core(_this)
    //       // _this.$root.core.updateSiteStats(50000);
    //       // _this.$root.core.getCurrentRate();
          
    //     }
      
    //   } catch (ex) {
    //     // console.log(ex);
    //     tronConnectAttemptsCounter++;
    //     if(tronConnectAttemptsCounter > 5 && (ex.message == "No Binance wallet found" || ex.message == "No Metamask extension found")){
    //       _this.noWallet = true;
    //       _this.$root.core = new Core(_this)
    //       // _this.$root.core.updateSiteStats(50000);
    //       return;
    //     }

    //     if (tronConnectAttemptsCounter > 10) {

    //       if (window.ethereum == undefined) {

    //         _this.$root.core = new Core(_this)
    //         // _this.$root.core.updateSiteStats(50000);

    //       } else {
    //         alert('Switch to  main network, please.')
    //       }
    //     } else {
    //       setTimeout(initContract, 500);
    //     }
    //   }

      
    // }, 300);
   
  },

  // watch: {

  //   walletUnlocked: function () {
  //     let _this = this;
  //     setTimeout(function check() {
  //       if(_this.walletUnlocked === false) {
  //         _this.checkWalletOption(_this.selectedWallet)
  //       setTimeout(check, 1000)
  //       }else if (_this.walletUnlocked === true) {
  //     }
  //     },0);
  //   }

    
  // }
}


</script>
